// eslint-disable-next-line no-redeclare,no-unused-vars
function detail(param) {
  var view = param.view;
  var object = param.object;
  var objectSub = param.objectSub;
  var row = {};
  if (param.bulk) row.id = 'bulk';
  else if (param.id) row = getData(object, param.id);
  else row = dataNew(param);
  if (isEmptyObject(row)) return;
  if (!param.func) param.func = 'detail';
  param.id = row.id;
  param.ident = [object, objectSub, row.id].filter(Boolean).join('_');
  param.type = 'detail';
  param.detail = true;
  var disabledSettings = getObjectValue(fields, [object, 'disabled', 'read']);
  if (
    disabledSettings &&
    isArray(disabledSettings) &&
    checkInArray(role, disabledSettings)
  )
    param.noTrashBtn = true;
  var shortKey = getParamSettings(param, 'short', null, true, true);
  if (!param.title && param.id)
    param.title = getData(object, param.id, shortKey, objectSub);
  if (!param.title) param.title = 'Neuer Eintrag';

  if (!view) {
    // build in popup
    if (param.modal) {
      view = buildPopup(param);
      view.addClass('modal-detail');
    }

    // build in thin view
    else if (param.thinView) {
      param.objIdent = [object, objectSub].filter(Boolean).join('_');
      view = buildView(param);
      view.addClass('view-detail view-small');
      view.body = $(
        '<div id="' + param.ident + '_body" class="view-body"></div>'
      ).appendTo(view.main);
    }

    // build in view
    else {
      param.objIdent = [object, objectSub].filter(Boolean).join('_');
      view = buildView(param);
      view.addClass('view-detail');
      view.openOverview = function () {
        if (views.overview) views.overview.show(true);
        else {
          var pm = {
            func: 'overview',
            object: object,
            objectSub: objectSub,
            keepDetail: true,
          };
          pm.type = getParamSettings(param, 'type');
          ajax(pm);
        }
      };
      if (!param.noOverview && mobile != 'smartphone') {
        if (getScreenWidth() >= 1370) {
          if (getLocalFlag(param.objIdent + '_ViewOpen') && !param.directCall)
            view.openOverview();
        }

        $(
          '<button class="btn btn-light"><i class="icon fa-regular fa-table fa-fw"></i></button>'
        )
          .prependTo(view.left)
          .addTooltip('Übersicht anzeigen')
          .on(click, function () {
            toggleLocalFlag(param.objIdent + '_ViewOpen');
            if (views.overview && !views.overview.hasClass('d-none'))
              views.overview.hide();
            else view.openOverview();
          });
      }
      if (mobile != 'smartphone') {
        var contNav = $(
          '<div class="view-nav" data-mdb-perfect-scrollbar="true"></div>'
        ).appendTo(view.left);
        new mdb.PerfectScrollbar(contNav[0], {suppressScrollX: true});
        view.nav = $(
          '<ul class="nav flex-column nav-pills gap-1"></ul>'
        ).appendTo(contNav);
      }

      // body
      view.body = $(
        '<div id="' + param.ident + '_body" class="view-body"></div>'
      ).appendTo(view.main);

      // sidebar
      new mdb.PerfectScrollbar(view.right[0], {suppressScrollX: true});
      var contCard = $('<div class="card"></div>').appendTo(view.right);
      view.sidebar = $(
        '<ul class="list-group list-group-flush"></ul>'
      ).appendTo(contCard);
    }
    view.id = row.id;
    if (row.id != 'new') view.attr('data-id', row.id);

    // title
    if (row.loading) row.icon = 'spinner fa-spin';
    view.header.val = function (value, object, id, key) {
      if (!value) return view.setTitle(param.title);
      var icon = null;
      if (key == 'loading')
        icon = value ? 'spinner fa-spin' : getParamSettings(param, 'icon');
      else if (key != 'warning') param.title = value;
      view.setTitle(
        param.title,
        icon,
        row.warning,
        getParamSettings(param, 'label')
      );
    };
    if (param.title) view.header.val(param.title);
    if (row.id) {
      register(view.header, object, row.id, shortKey);
      register(view.header, object, row.id, 'warning');
      if (isset(row.loading)) register(view.header, object, row.id, 'loading');
    }
  }

  // delete button
  if (
    row.id &&
    !param.noTrashBtn &&
    (!parameter[object].noModalRemove || !view.modal) &&
    !isHidden(fields[object].disabled, row)
  ) {
    if (!row.disabled)
      view.trashBtn = $(
        '<button class="btn btn-light trash"><i class="icon fa-regular fa-trash-alt fa-fw"></i></button>'
      )
        .prependTo(view.buttonsRight)
        .on(click, function () {
          buildConfirmPopup(param.title + ' wirklich löschen?', function () {
            ajax({
              object: object,
              id: row.id,
              post: true,
              send: {disabled: 1},
            });
            view.close();
          });
        })
        .addTooltip(info.context.disableRow);
    else
      view.trashBtn = $(
        '<button class="btn btn-light"><i class="icon fa-regular fa-trash-undo fa-fw"></i></button>'
      )
        .prependTo(view.buttonsRight)
        .on(click, function () {
          ajax({
            object: object,
            id: row.id,
            post: true,
            send: {disabled: 0},
            callback: function () {
              view.update();
            },
          });
        })
        .addTooltip(info.tooltip.activateRow);
  }

  buildField(
    view.buttonsRight,
    {object: object, objectSub: objectSub, row: row},
    'color'
  ).prependTo(view.buttonsRight);

  // next button
  if (param.next) {
    $(
      '<button class="btn btn-light"><i class="icon fa-regular fa-arrow-right fa-fw"></i></button>'
    )
      .prependTo(view.buttonsRight)
      .on(click, function () {
        param.next(view);
      })
      .addTooltip(info.tooltip.nextRow);
    view.next = param.next;
  }

  // previous button
  if (param.prev) {
    $(
      '<button class="btn btn-light"><i class="icon fa-regular fa-arrow-left fa-fw"></i></button>'
    )
      .prependTo(view.buttonsRight)
      .on(click, function () {
        param.prev(view);
      })
      .addTooltip(info.tooltip.previousRow);
    view.prev = param.prev;
  }

  // share button
  $(
    '<button class="btn btn-light"><i class="icon fa-regular fa-share fa-fw"></i></button>'
  )
    .prependTo(view.buttonsRight)
    .on(click, function () {
      navigator.clipboard.writeText(
        document.location.origin +
          '/' +
          object +
          (objectSub ? '+' + objectSub : '') +
          ',' +
          row.id
      );
      showInfo(info.success.successShare, 'success');
    })
    .addTooltip(info.tooltip.share);

  // full size button
  if (param.modal)
    $(
      '<button class="btn btn-light"><i class="icon fa-regular fa-expand-arrows fa-fw"></i></button>'
    )
      .prependTo(view.buttonsRight)
      .on(click, function () {
        view.close();
        param.modal = false;
        delete param.view;
        detail(param);
      })
      .addTooltip(info.tooltip.openFullRow);

  // bulk button
  if (param.bulk)
    $(
      '<button class="btn btn-light"><i class="icon fa-regular fa-layer-group fa-fw"></i></button>'
    )
      .prependTo(view.buttonsRight)
      .on(click, function () {
        var pm = {
          post: true,
          object: object,
          objectSub: objectSub,
          send: {func: 'bulk'},
        };
        param.table.filtered(pm.send);
        if (pm.send.all || pm.send.ids) ajax(pm, {noTimeout: true});
        view.close();
      })
      .addTooltip(info.tooltip.saveBulk);

  // multi subs
  var dropdown = {list: []};
  $.each(parameter[object], function (key, val) {
    if (!str_starts_with(key, 'form')) return;
    else if (str_starts_with(val, 'form')) return;
    var sub = key.substr(4);
    var subId = sub.charAt(0).toLowerCase() + sub.substr(1);
    var label = parameter[object]['labelDetail' + sub];
    if (!label) label = parameter[object]['label' + sub];
    if (!label && !test) return;
    else if (!label) label = key;

    dropdown[key] = {
      label: label,
      noAjax: true,
      jsFunc: function () {
        view.update({objectSub: subId, formFix: true});
      },
    };

    if (
      parameter[object]['hide' + sub] &&
      checkWhere({}, {role: parameter[object]['hide' + sub]})
    )
      return;
    else if (
      parameter[object].showViews &&
      inArray(subId, parameter[object].showViews)
    )
      dropdown.list.push(key);
    else if (parameter[object]['where' + sub]) {
      if (
        checkWhere(row, parameter[object]['where' + sub], {
          object: object,
          objectSub: objectSub,
        })
      )
        dropdown.list.push(key);
    } else dropdown.list.push(key);
  });
  if (Object.values(dropdown).length > 1) {
    var btn = $('<div class="dropdown btn-cont"></div>').prependTo(
      view.buttonsRight
    );
    $(
      '<button class="btn btn-light" data-bs-toggle="dropdown" aria-expanded="false"><i class="icon fa-regular fa-eye fa-fw"></i></button>'
    )
      .addTooltip('Ansicht wechseln')
      .appendTo(btn);
    if (dropdown.list.length) buildDropdown(btn, dropdown, false, false, true);
  }

  // sidebar button
  setTimeout(function () {
    $(
      '<button class="btn btn-light"><i class="icon fa-regular fa-rectangle-portrait fa-fw"></i></button>'
    )
      .appendTo(view.buttonsRight)
      .on(click, function () {
        if (param.modal) {
          view.sidebar.toggleClass('d-none');
          view.main.parent().toggleClass('width-sidebar');
        } else view.right.toggleClass('d-none');
      })
      .addTooltip(info.tooltip.showSidebar);
  }, 500);

  // select in table && !param.callback
  if (row.id && !param.callback && elements.tables[object + '_els'])
    $.each(elements.tables[object + '_els'], function (i, table) {
      if (
        table &&
        table.ids &&
        table.where &&
        table.selectRow &&
        inArray(row.id, table.ids)
      )
        table.selectRow(row.id);
    });

  // build form
  if (row.id && parameter[object].whereForm && !param.bulk && !param.formFix) {
    if (
      !(
        parameter[param.object].whereForm.fix &&
        inArray(objectSub, parameter[param.object].whereForm.fix)
      ) &&
      parameter[param.object].whereForm[objectSub] !== 'fix'
    ) {
      $.each(parameter[param.object].whereForm, function (sub, cond) {
        if (
          isPlainObject(cond) &&
          checkWhere(row, cond, {object: object, nullAsEmpty: true})
        )
          objectSub = param.objectSub = sub;
      });
    }
  }

  var formId = 'form';
  formId = getParamSettings(
    {object: object, objectSub: objectSub, returnKey: true},
    formId,
    null,
    null,
    true
  );
  if (str_starts_with(parameter[param.object][formId], 'form'))
    formId = parameter[param.object][formId];
  var formKeys = parameter[param.object][formId];
  view.formId = formId;
  param.view = view;
  buildForm(view.body, param, formKeys);

  // unseen
  if (isset(row.chat_unseen)) {
    setData(object, row.id, 'chat_unseen', 0);
    setData(
      object,
      row.id,
      'chat',
      data[object][row.id].chat,
      false,
      false,
      false,
      true
    );
  }
  if (
    row.id &&
    row.unseen &&
    !fields[object].unseen.notVisited &&
    (!fields[object].unseen.cond || checkWhere(row, fields[object].unseen.cond))
  )
    ajax({post: true, object: object, id: row.id, send: {unseen: 0}});

  // add detail block menu
  if (view.nav) {
    var navIdent = 'nav' + rand();
    view.body.attr({
      'data-mdb-spy': 'scroll',
      'data-mdb-target': '#' + navIdent,
    });
    view.nav.parent().attr('id', navIdent);
    new mdb.ScrollSpy(view.body[0], {offset: 150});
  }

  // scroll
  if (!param.modal) {
    view.body.addClass('scrollable');
    view.setHeight();
  }

  if (localStorage.getItem('configuration')) administrateDetail(param);
  if (!param.modal) handleMenuActive(param);
  return view;
}
